import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAJy0kW_j0J7Vrmg8MuRsWCqWp3CBpEgrw",
  authDomain: "famosotwl-9f8df.firebaseapp.com",
  projectId: "famosotwl-9f8df",
  storageBucket: "famosotwl-9f8df.appspot.com",
  messagingSenderId: "703858302887",
  appId: "1:703858302887:web:68d1230b206f00b91a6082"
};

const app = initializeApp(firebaseConfig);

export default getFirestore(app);