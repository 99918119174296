export class ViewerDataModel {
    constructor(twitchName, cardName, cardRarity, cardLevel, bornDate, fruit, state, createdAt) {
      this.twitchName = twitchName
      this.cardName = cardName
      this.cardRarity = cardRarity
      this.cardLevel = cardLevel
      this.bornDate = bornDate
      this.fruit = fruit
      this.state = state
      this.createdAt = createdAt
    }
} 