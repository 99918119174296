import { collection, doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import db from './FirebaseIntegration'
import {ViewerDataModel} from './ViewerDataModel'

export default class RepositoryManager {

    static registerViewerData = async (viewerData) => {
        const newData = {
            twitch_name: viewerData.twitchName,
            card_name: viewerData.cardName,
            card_rarity: viewerData.cardRarity,
            card_level: viewerData.cardLevel,
            born_date: viewerData.bornDate,
            fruit: viewerData.fruit,
            state: viewerData.state,
            lastUpdate: serverTimestamp()
          };

          if (!viewerData.createdAt) {
            newData["createdAt"] = serverTimestamp()
          }
        
        const colletionRef = collection(db, "viewers_data");

        try {
            const ref = doc(colletionRef, newData.twitch_name);
            await setDoc(ref, newData);

            alert("Cadastrado/Atualizado com sucesso")
          } catch (e) {
            console.error("Error adding document: ", e);
          }
    }

    static getViewerData = async (twitchName) => {
        try {
        const docRef = doc(db, "viewers_data", twitchName);
        const snapshot = await getDoc(docRef);
        const data = snapshot.data()
        const result = new ViewerDataModel(data["twitch_name"], data["card_name"], data["card_rarity"], data["card_level"], data["born_date"].toDate(), data["fruit"], data["state"], data["createdAt"])
        
        return result
        } catch (e) {
            console.error("Error getting document: ", e);
        }
    }
  }